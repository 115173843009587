@media only screen and (max-width: 370px) {
  .background1 {
    /* height: 130vh !important; */
  }
}
@media only screen and (max-width: 480px) {
  .container2 {
    height: 115vh !important;
  }
}


@media only screen and (max-width: 520px) {
  .hidden-property-desktop {
    display: none;
  }
}

@media only screen and (min-width: 520px) {
  .hidden-property-mobile {
    display: none;
  }
}


@media only screen and (min-width: 640px) {
  .arrow-btn-left {
    right: 90%;
    top: 100px;
  }

  .arrow-btn-right {
    left: 90%;
    top: 100px;

  }
}

@media only screen and (max-width: 767px) {
  .background1 {
    margin: auto;
    width: 70%;
    padding: 20px;
    margin-top: 20px;
    flex-grow: 0;
    height: 300px;

  }
  .container2 {
    /* height: 100vh !important; */
  }

  .rounded-border {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }
}

@media only screen and (max-width: 850px) {
  .background1 {
    height: 40vh;
  }
}
@media only screen and (min-width: 1215px) {
  .left-arrow-earthing{
  left:-68px;
  }
  .right-arrow-earthing{
  
  right:-68px;
}
}
