
.bg-img-careers { 
    position: relative; 
    height: 120vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('/public//Image/industry-1801661.jpg');
    background-size: cover;
}


.bg-img-careers::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgb(0 0 0 / 57%);
}


