
.background {
    position: relative;
    margin-top:40px;
    margin-bottom: 40px;
    /* background-color: gray !important; */
}

.background img {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 70vh;
}

.my-input::placeholder {
    color:#5D5D5D;
   
   
  }


  .white-image {
    filter: brightness(0) invert(1);
  }
  
