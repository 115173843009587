.bg{
    background-color: #e9e9e9 ;
    color: #5D5D5D;
    /* display: flex; */
    gap: 20px;
    align-items: center;
    justify-content: start;
    padding: 30px;

}
.white-box-section{
    background-color: white;
    border-radius: 20px;
    height: 120px;
    width: 120px;
    display: flex;
    flex-direction: column;
    color: black !important;
    align-items: center;
    justify-content: center;
}
.form-btn{
    background-color: #F56B39;
    color: white;
    font-size: 32px;
    border-radius: 40px;
}