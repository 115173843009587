.animated-bar {
  transition: width 10s ease-in-out;
  width: 0;
}
.animated-bar {
  transition: width 10s ease-in-out;
  width: 0;
}

.content {
  font: normal normal normal 40px/48px Poppins;
  margin-top: 50px;
}

.bg-image {
  height: 180px !important;
  object-fit: cover !important;
}
