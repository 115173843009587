.ongrid-btn{
    background-color: white;
    /* top: 0; */
    padding: 5px 12px;
    /* left: 0; */
    font-size: 10px;
}
.card-hover:hover{
    /* background-color: rgb(0 0 0 / 57%); */

}
.card-hover:hover {
    box-shadow: 0 40px 60px -20px rgba(12, 5, 62, 0.15);
    z-index: 100;
  }
.transition { animation: fadeIn 5s; }
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }


  
  