.font-weight500{
    font-weight: 500;
}
@media only screen and (max-width: 1090px) {
    .hidden-property-mob-img3{
      display:none;
    }
  }
  @media only screen and (min-width: 1090px) {
    .hidden-property-mob-img3{
      display:block;
    } 
  }

  @media only screen and (max-width: 740px) {
    .hidden-property-mob-img2{
      display:none;
    }
  }
  @media only screen and (min-width: 740px) {
    .hidden-property-mob-img2{
      display:block;
    } 
  }