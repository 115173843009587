/* .container2 {
    background-color: #fff4e6;
    width: 100%;
    height: 90vh;
    top: 0px;
    left: 0px;
    opacity: 1;
}

.rounded {
    width: 50%;
    height: 50vh;
} */

.container2 {
  background-color: #fff4e6;
  width: 100%;
  height: 90vh;
  top: 0px;
  left: 0px;
  opacity: 1;
}

.rounded {
  width: 50%;
  height: 62vh;
  min-width: 200px;
}

.pocket-friendly-card {
  bottom: 23px;
  left: 45%;
  z-index: 2;
  border-radius: 10px;
}

.enviroment-friendly-card {
  top: 50px;
  left: -80px;
  z-index: 2;
  border-radius: 10px;
}

.trending-card {
  bottom: -20px;
  right: 10%;
  z-index: 2;
  border-radius: 10px;
}
.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}

@media only screen and (max-width: 600px) {
  .pocket-friendly-card {
    bottom: 23px;
    left: 25%;

  }
  
  .enviroment-friendly-card {
    top: -21px;
    left: 73px;

    }
  
  .trending-card {
    bottom: -20px;
    right: 10%;
  }
}

