.container2 {
  background-color: #fff4e6;
  width: 100%;
  height: 90vh;
  top: 0px;
  left: 0px;
  opacity: 1;
}


.background1 {
  width: 50%;
  height: 62vh;
  min-width: 200px;
  border-radius: 0 40px 0 40px;
  margin-top: -60px;
  z-index: 5;
  flex-grow: 1;
}

.remove-bg-color {
  background: transparent !important;
  background-color: rgb(247, 243, 243) !important;
}

.rounded-border {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

/* Add your existing styles here */

.animated-bar {
  transition: width 100s ease-in-out;
  width: 0;
}

/* .animated-bar-100 {
transition: width 100s ease-in-out;
width: 0;
} */

.solar {
  /* font-family: normal normal normal  Poppins; */
  color: #000000;
  opacity: 1;
  font-size: 18px;
}

.power {
  font-family: normal normal normal 36px/43px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.Calculator {
  font-family: normal normal bold 53px/64px Lato;
  letter-spacing: 0px;
  color: #f56b39;
  opacity: 1;
}

.testimonial-section {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.testimonial-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.testimonial-wrapper-content {
  font-weight: 500;
}

.testimonial-section.animate .testimonial-wrapper {
  animation: scrollAnimation 5s ease-in-out;
  animation-fill-mode: both;
}

.view-more-font {
  font-family: normal normal normal Poppins;
}

.animation {
  animation: scrollAnimation 3s ease-in-out;
  /* animation-fill-mode: both; */
}

.border-rounded {
  border-radius: 40px;

}


.btn-plus {
  background-color: #42eafc;
  border-radius: 94px;
  color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}


/* .calcution-btn{
  background-color: #FFC680;
  color: black;
  border-radius: 5px;
  padding: 10px;

} */


.input-radio>span {
  color: grey !important;
  font-weight: 500 !important;
}


@keyframes scrollAnimation {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* .slide-pos.show {
--left: 100px;
--top: 30px;
} */

.slide-pos.show {
  --left: 200px;
  --top: 30px;
}



/* Global CSS */
.checkmark {
  color: black;
}

/* In your CSS */
.placeholder-ml-4::placeholder {
  margin-left: 0.5rem;
  /* Adjust the value as needed */
}

.arrow-btn-left {
  right: 83%;
  top: -57px;

}

.arrow-btn-right {
  left: 83%;
  top: -57px;

}
.left-arrow-earthing{
  left:0
  }
  .right-arrow-earthing{
  
  right:0
}