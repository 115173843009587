.footer{
    background-color: black;
    top: 0;
    left: 0 
}
.x-logo-twitter{
    border: 3px solid white;
    border-radius: 30px;
}
.text-align-style>p{
    text-align: start !important;
}