.bg-layer {
  position: relative;
  z-index: 0;
}
.bg-layer::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.433);
  top: 0;
}
